<script setup>
import { defineProps, defineEmits, reactive } from 'vue'
import VTable from '@/components/ui/table/VTable'
import VModal from '@/components/ui/modal/VModal'

const emit = defineEmits(['selected', 'close'])
const props = defineProps({
  offers: {
    type: [Array, Object],
    required: false
  },
  showModal: {
    type: Boolean,
    required: false
  },
  idx: {
    type: Number,
    required: true
  }
})

const headerRow = [
  {
    name: 'Наименование позиции',
    value: 'name'
  },
  {
    name: 'Доп х-ки',
    value: 'added'
  },
  {
    name: 'Кол-во',
    value: 'counter'
  },
  {
    name: 'Цена за ед',
    value: 'price'
  },
  {
    name: 'Сумма с НДС',
    value: 'sum'
  }
]
const rows = reactive([])
const candidateList = reactive([])

const renderRows = () => {
  const offer = props.offers[props.idx]
  offer.offers.forEach(offer => {
    const position = offer.answers.position
    let parameters = ''
    position.parameters.forEach(par => {
      parameters += par.full_name + '; '
    })

    const data = {
      name: position.name,
      added: parameters,
      counter: position.quantity,
      price: position.price,
      sum: position.price * position.quantity,
      id: offer.id,
      position_id: offer.position_id
    }
    rows.push(data)
  })
}

const successCloseModal = () => {
  const sList = new Set(candidateList)
  emit('selected', Array.from(sList))
  closeModal()
}

const cancel = () => {
  candidateList.length = 0
  closeModal()
}

const closeModal = () => {
  emit('close')
}

renderRows()

function selectedItems (selected) {
  candidateList.length = 0
  candidateList.push(...selected)
}
</script>

<template>
  <VModal
    v-if="showModal"
    modalInnerClass="modal-table bg--white"
    :scroll-y="true"
    @close="closeModal"
  >

    <h2 class="modal__title">РАЗДЕЛИТЬ ЛОТ</h2>
      <h4 class="modal__subtitle">Заявка</h4>

    <VTable
      :isTouchBar="false"
      :touchBarList="[]"
      :headRow="headerRow"
      :rows="rows"
      keySelected=""
      @selected="selectedItems"
    />

    <div class="buttons-wrap center">
      <button
        class="btn btn-primary btn-primary--padding btn-bg-green"
        @click="successCloseModal"
      >
        Сохранить
      </button>

      <button
        class="btn btn-primary btn-primary--padding btn-bg-green"
        @click="cancel"
      >
        Отмена
      </button>
    </div>
  </VModal>
</template>
