<template>
  <div class="best-offer-card">
    <div class="best-offer-card__header p-30 bg--yellow">
      <div
        class="best-offer-card__content grid grid--col-3"
        :class="{ 'best-offer-card__content--with-buttons' : withButtons }"
      >
        <span v-if="!hideCounter" >{{ count }} Место</span>
        <span>№ заявки: {{ counterOffer }}</span>
      </div>
    </div>
    <div class="best-offer-card__body bg--light-gray p-30">
      <div class="grid grid--col-3">
        <VTextBlock
          subtitle="Участник"
          :description="item?.user?.organization_name"
        />
        <VTextBlock
          subtitle="ИНН"
          :description="item?.user.inn"
        />
        <VTextBlock
          subtitle="Дата и время подачи заявки"
          :description="item.offers[0].updated_at.slice(0, 16).split('T').join(' ')"
        />

        <VTextBlock
          subtitle="Тел. ответственного"
          :description="item.lot?.responsible_phone"
          is-tel
        />
        <VTextBlock
          subtitle="ОГРН"
          :description="item.user.msrn"
        />
        <VTextBlock
          subtitle="Ценовое предложение"
          :description="formatPrice(getCurrentPrice())"
        />
        <VTextBlock
          subtitle="Адрес электронной почты"
          :description="item.user.email"
          is-email
        />
        <VTextBlock
          subtitle="Адрес"
          :description="item.lot?.delivery_address.name"
        />
      </div>
      <div
        v-if="withButtons && isTradeActive && !isBidder"
        class="best-offer-card__buttons buttons-wrap buttons-wrap--column ml-a"
      >
        <button
          @click="onSuccess(item)"
          :class="['btn btn-primary btn-primary--padding btn-bg-yellow', isClick ? 'btn-bg-transparent' : '']"
        >
          {{ isClick ? 'Открепить' : 'Утвердить' }}
        </button>

        <button
          v-if="!type"
          class="btn btn-primary btn-primary--padding btn-bg-green"
          @click="openVModal"
        >
          Разделить
        </button>
      </div>
    </div>

    <teleport to="body">
      <VOrganizerSummarizingModalDivideLot
        v-if="!isBidder"
        :show-modal="showVModal"
        :offers="offers"
        :idx="idx"
        @close="closeVModal"
        @selected="selectedItems"
      />
    </teleport>
  </div>
</template>

<script>
import { ref } from 'vue'
import VTextBlock from '@/components/textblock/VTextBlock'
import { formatPrice } from '@/utils/utils'
import useVModal from '@/use/Base/useVModal'
import VOrganizerSummarizingModalDivideLot
from '@/components/views/Organizer/Summarizing/Modal/VOrganizerSummarizingModalDivideLot'
import { useStore } from 'vuex'
import { useSelectedItems } from '@/use/Form/Organizer/useSelectedItems'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    type: {
      type: Boolean,
      required: false
    },
    count: {
      type: Number,
      required: true
    },
    withButtons: {
      type: Boolean,
      required: false,
      default: false
    },
    counterOffer: {
      type: Number,
      required: true
    },
    isTradeActive: {
      type: Boolean,
      required: false
    },
    hideCounter: {
      type: Boolean,
      required: false
    },
    offers: {
      type: [Array, Object],
      required: false
    },
    idx: {
      type: Number,
      required: false
    },
    isBidder: {
      type: Boolean,
      required: false
    }
  },
  setup (props) {
    const store = useStore()
    const isClick = ref(false)
    const { selectedItems, createItem } = useSelectedItems()

    const getCurrentPrice = () => {
      let sum = 0
      props.item.offers.forEach((offer) => {
        sum += +offer.price
      })
      return sum
    }

    const onSuccess = (item) => {
      const el = createItem(item, props.idx, props.offers[props.idx].offers, isClick)
      store.commit('offer/toggleApproveList', el)
      isClick.value = !isClick.value
    }
    return {
      ...useVModal(),
      isClick,
      onSuccess,
      getCurrentPrice,
      formatPrice,
      selectedItems
    }
  },
  components: {
    VTextBlock,
    VOrganizerSummarizingModalDivideLot
  }
}
</script>
