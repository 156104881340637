<script setup>
import { computed, defineProps } from 'vue'
import VTable from '@/components/ui/table/VTable'
import { Alert } from '@/extension/Alert/Alert'
import { formatPrice } from '@/utils/utils'

const props = defineProps({
  url: {
    type: String,
    required: true
  },
  action: {
    type: String,
    required: true
  }
})

const getUrl = computed(() => {
  return props.url
})

function changeObjectOffer (data) {
  data.forEach((offer, idx) => {
    offer.idTicket = idx + 1
    let sum = 0
    offer.offers.forEach((of) => {
      if (of.price > 0) {
        sum += +of.price
      }
    })

    offer.priceNum = formatPrice(sum)
    offer.updated_at = offer.offers[0].updated_at.slice(0, 16).split('T').join(' ')
    offer.grade = String(offer.ball).slice(0, 6)
  })
}

const transformObject = (data) => {
  if (!Array.isArray(data.all)) {
    return Alert.show('error', 'Таблица не поддерживает такой набор даннхы')
  }
  changeObjectOffer(data.all)
  return data.all
}

const headRow = [
  {
    name: '№ заявки:',
    value: 'idTicket'
  },
  {
    name: 'Дата и время подачи',
    value: 'updated_at'
  },
  {
    name: 'Ценовое предложение',
    value: 'priceNum'
  },
  {
    name: 'Системная оценка',
    value: 'grade'
  }
]

</script>

<template>
  <VTable
    :max-width="false"
    :head-row="headRow"
    :is-checkbox="false"
    :url-set-rows="getUrl"
    :url-action-query="action"
    :transform-object="transformObject"
  />
</template>
