<template>
  <ul class="best-offer-list grid" v-if="bestOfferList?.length">
    <li
      class="best-offer-list__item"
      v-for="(item, i) in bestOfferList"
      :key="item.id"
    >
      <VBestOfferCard
        :is-bidder="isBidder"
        :with-buttons="withButtons"
        :isTradeActive="isTradeActive"
        :item="item"
        :hide-counter="hideCounter"
        :type="type"
        :counter-offer="i + 1"
        :count="i + 1"
        :idx="i"
        :offers="offers"
      />
    </li>
  </ul>
</template>

<script>
import VBestOfferCard from '@/components/list/BestOfferList/VBestOfferCard'
export default {
  emits: ['onSuccess'],
  props: {
    bestOfferList: {
      type: Array,
      required: true
    },
    type: {
      type: Boolean,
      required: false
    },
    withButtons: {
      type: Boolean,
      required: false,
      default: false
    },
    isTradeActive: {
      type: Boolean,
      required: false
    },
    hideCounter: {
      type: Boolean,
      required: false
    },
    offers: {
      type: [Array, Object],
      required: false
    },
    isBidder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    VBestOfferCard
  }
}
</script>
